import './App.css';
import React, { lazy, Suspense } from "react";
import CacheBuster from 'react-cache-buster';
import appversion from '../package.json';
import { CookieSetting } from "cookiesetting-component";
// import Loading from './loading';
const Navbar = lazy(() =>
  import("./components/Navbar")
);
const Header = lazy(() =>
  import("./components/Header")
);
const About = lazy(() =>
  import("./components/About")
);
const ContactUs = lazy(() =>
  import("./components/ContactUs")
);
const SubFooter = lazy(() =>
  import("./components/SubFooter")
);
const Footer = lazy(() =>
  import("./components/Footer")
);
// function App() {
const App = () => {
  const appVersion = appversion.version;

  return (
    <>
      <CacheBuster
        currentVersion={appVersion}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
      //  loadingComponent={<Loading />} //If not passed, nothing appears at the time of the new version check.
      >

      </CacheBuster>
      <Suspense fallback={<div />}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<div />}>
        <Header />
      </Suspense>
      <Suspense fallback={<div />}>
        <About />
      </Suspense>
      <Suspense fallback={<div />}>
        <ContactUs />
      </Suspense>
      <Suspense fallback={<div />}>
        <SubFooter />
      </Suspense>
      <Suspense fallback={<div />}>
        <Footer />
      </Suspense>
      {/* <Navbar />
      <Header />
      <About />
      <Contact />
      <SubFooter />
      <Footer /> */}
      <CookieSetting
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK}
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
    </>
  );
}

export default App;
